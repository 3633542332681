/**
 * get intervention from opData
 * @param {object} opData
 * @return {string} surgeon - surgeonId
 */
const getInterventions = (opData) => {
    let intervention = null;
    if (opData._interventions && opData._interventions.main && opData._interventions.main.length) {
        intervention = opData._interventions.main[0].display;
    }
    return intervention;
};

export default getInterventions;
