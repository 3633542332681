// @ts-check
/**
 * Fetch durations from op data
 * @param {PlanBox} opData
 * @return {InternalTimestampsOpData}
 */
const getDurations = (opData) => {
    /** @type {InternalTimestampsOpData} */
    let durations = {
        duraRoomLock: null,
        duraRoomLockPre: null,
        duraRoomLockOp: null,
        duraRoomLockPost: null,
        duraCpb: null
    };

    if (opData?._internalTimestamps) {
        /** @type {InternalTimestampsOpData} */
        durations = {...opData._internalTimestamps};
    }
    return durations;
};

export default getDurations;
