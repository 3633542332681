import {luxonToken} from "../contexts/dates";

// @ts-check
const {DateTime} = require("luxon");

/**
 * @param {string} dateISO
 * @param {string} timezone
 * @return {DateTimeType}
 */
export const getDateTimeFromISOWithTimezone = (dateISO, timezone) => DateTime.fromISO(dateISO, {zone: timezone});

/**
 * @param {string} dateISO
 * @return {DateTimeType}
 */
export const getDateTimeFromISO = (dateISO) => DateTime.fromISO(dateISO, {setZone: true});

/**
 * @param {Date} date
 * @param {string} timezone
 * @return {DateTimeType}
 */
export const getDateTimeFromJSDate = (date, timezone) => DateTime.fromJSDate(date, {zone: timezone});

/**
 * calculate difference between a and b in a given unit
 * @param {DateTimeType} a
 * @param {DateTimeType} b
 * @param {"years"|"months"|"days"|"weeks"|"hours"|"minutes"|"seconds"} unit in plural
 * @return {Number}
 */
export const diffDateTime = (a, b, unit) => a.diff(b, unit).toObject()[unit];

/**
 * Get the current DateTime
 *
 * @return {DateTime}
 */
export const nowDateTime = () => DateTime.now();

/**
 * Get the current DateTime with given timezone
 * @param {String} timezone
 * @return {DateTime}
 */
export const nowDateTimeWithTimezone = (timezone) => DateTime.local({zone: timezone});

/**
 * compare given DateTime object in a given unit
 *
 * @param {DateTimeType} a
 * @param {DateTimeType} b
 * @param {DateTimeUnit} unit
 * @param {string} timezone
 * @return {Boolean}
 */
export const areSameDates = (a, b, unit, timezone) => a.setZone(timezone).hasSame(b.setZone(timezone), unit);

/**
 * format give dateTime object to the give format
 *
 * @param {DateTimeType} date
 * @param {String} format
 * @return {String}
 */
export const format = (date, format) => date.toFormat(format);

/**
 * format give dateTime object to the give format
 *
 * @param {DateTimeType} date
 * @param {String} format
 * @param {String} locale "en-US" or "de"
 * @return {String}
 */
export const formatWithLocale = (date, format, locale) => date.toFormat(luxonToken[format][locale]);

/**
 * add given number of unit to the date time object
 *
 * @param {DateTimeType} date
 * @param {DateTimeUnit} unit
 * @param {Number} num
 * @return {DateTimeType}
 */
export const plusDT = (date, unit, num) => date.plus({[unit]: num});

/**
 * subtract given number of unit from the date time object
 *
 * @param {DateTimeType} date
 * @param {DateTimeUnit} unit
 * @param {Number} num
 * @return {DateTimeType}
 */
export const minusDT = (date, unit, num) => date.minus({[unit]: num});

/**
 * Convert given ISO String to DateTime and return the start of the given unit
 *
 * @param {DateTimeType} date
 * @param {DateTimeUnit} unit
 * @return {DateTimeType}
 */
export const startOf = (date, unit) => date.startOf(unit);

/**
 * Convert given ISO String to DateTime and return the end of the given unit
 *
 * @param {DateTimeType} date
 * @param {DateTimeUnit} unit
 * @return {DateTimeType}
 */
export const endOf = (date, unit) => date.endOf(unit);

/**
 * Convert hour and minute in DateTime to hour (16:30 -> 16.5)
 *
 * @param {DateTimeType} date
 * @return {Number}
 */
export const convertHourMinuteDateTimeToHours = (date) => date.hour + date.minute / 60;

/**
 * Set given localHour to the given Date
 *
 * @param {DateTimeType} date
 * @param {Number} localHour
 * @return {DateTimeType}
 */
export const setLocalHourToDateTime = (date, localHour) => {
    return date.set({
        hour: Math.floor(localHour),
        minute: (localHour - Math.floor(localHour)) * 60,
        second: 0,
        millisecond: 0
    });
};
/**
 * set given values to the DateTime in organization timezone
 * @param {DateTimeType} date
 * @param {DateObjectUnits} values
 * @return {DateTimeType}
 */
export const setDateTime = (date, values) => date.set(values);

/**
 * sort dateTime
 * @param {DateTimeType} a
 * @param {DateTimeType} b
 * @return {number}
 */
export const sortDateTime = (a, b) => a?.toMillis() - b?.toMillis();
