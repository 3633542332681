/**
 * @fileoverview utility for local storage
 * It is used for options during a same logged-in session
 * Key consists of given page and key (page_key)
 *
 * examples:
 * {
 *      "DayView_opRooms": ["ROOM1", "ROOM2"],
 *      "DayView_roomsFilter": [KOP01"] // multiselect of rooms + "all", "plannedOps", "emptyRooms"
 *      "DayView_roomsFilter": ["Discipline A"] // multiselect of disciplines
 *      "OpManage_opRooms": ["ROOM1", "ROOM2"],
 *      "OpManage_planSetting": "both", // "new" or "both"
 *      "OpManage_roomsFilter": ["plannedOps", "KOP01"],
 *      "OpManage_disciplinesFilter": ["Discipline A", "Discipline B"]
 * }
 */
import logger from "./logger_pino";

const STRING_ITEMS = [
    "planSetting",
    "language",
    "dateRange_end",
    "dateRange_start",
    "patientAgeFrom",
    "patientAgeTo",
    "patientNameFilter",
    "priorityFilter"
];

/**
 * get value of given key of given page
 * @param {String} page
 * @param {String} key
 * @return {Object|string|number}
 */
const getLocalStorageItem = (page, key) => {
    let result;
    const item = localStorage.getItem(`${page}_${key}`);
    if (STRING_ITEMS.includes(key)) {
        result = item;
    } else {
        try {
            result = JSON.parse(item);
        } catch (e) {
            logger.error(e);
        }
    }
    return result;
};

/**
 * set value of given key of given page
 * @param {String} page
 * @param {String} key
 * @param {Object|string|number} value
 */
const setLocalStorageItem = (page, key, value) => {
    const valueString = typeof value === "string" ? value : JSON.stringify(value);
    localStorage.setItem(`${page}_${key}`, valueString);
};

const clearLocalStorage = () => {
    localStorage.clear();
};

export {getLocalStorageItem, setLocalStorageItem, clearLocalStorage};
