/**
 * Format username of given email
 * @param {String} email
 * @param {Object} usernames
 * @return {String}
 */
const formatUsername = (email, usernames) => {
    const nameFormatted =
        usernames[email] &&
        usernames[email].firstName &&
        usernames[email].lastName &&
        usernames[email].lastName + " " + usernames[email].firstName[0].toUpperCase() + ". ";
    return nameFormatted || email;
};

export default formatUsername;
