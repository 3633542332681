// @ts-check

import {diffDateTime, getDateTimeFromISO} from "./luxon_helpers";

/**
 * check if the given two durations are overlapping.
 *
 * @param {string} startA ISO string
 * @param {string} endA ISO string
 * @param {string} startB ISO string
 * @param {string} endB ISO string
 * @return {boolean}
 */
const hasOverlap = (startA, endA, startB, endB) => {
    const msStartA = getDateTimeFromISO(startA).toMillis();
    const msEndA = getDateTimeFromISO(endA).toMillis();
    const msStartB = getDateTimeFromISO(startB).toMillis();
    const msEndB = getDateTimeFromISO(endB).toMillis();

    return Math.max(msStartA, msStartB) < Math.min(msEndA, msEndB);
};

/**
 * @typedef {object} ResponseCheckOpOverlap
 * @property {boolean} hasOverlap true if a target op is overlapping to any ops.
 * @property {PlanBox[]} overlappedOps ops that are overlapping including target op.
 * @property {number} adjustIndex used to set offset for the overlap icon, when some ops start at same time
 */

/**
 * check if there is any overlap for given op vs ops
 *
 * @param {PlanBox} targetOp
 * @param {PlanBox[]} ops
 * @return {ResponseCheckOpOverlap}
 */
export const checkOpOverlap = (targetOp, ops) => {
    const {
        _internalTimestamps: {duraRoomLockPre: {dtStart: startA} = {}, duraRoomLockPost: {dtEnd: endA} = {}} = {},
        id,
        _location: {reference: location} = {}
    } = targetOp;
    if (!startA || !endA || !location) {
        return {hasOverlap: false, overlappedOps: [], adjustIndex: 0};
    }
    const overlappedOps = ops.filter(
        ({
            _internalTimestamps: {duraRoomLockPre: {dtStart: startB} = {}, duraRoomLockPost: {dtEnd: endB} = {}} = {},
            id: idB,
            _location: {reference: locationB} = {}
        }) => hasOverlap(startA, endA, startB, endB) && id !== idB && location === locationB
    );
    // ops which starts same or within 10 min, including self
    const sameStartOps = ops.filter(
        ({_internalTimestamps: {duraRoomLockPre: {dtStart: startB} = {}}}) =>
            Math.abs(diffDateTime(getDateTimeFromISO(startA), getDateTimeFromISO(startB), "minutes")) < 10
    );

    const adjustIndex = sameStartOps.findIndex((op) => op.id === id);

    return {hasOverlap: overlappedOps.length > 0, overlappedOps: [targetOp, ...overlappedOps], adjustIndex};
};
