// @ts-check
/**
 * Filters an object and keeps the true values only
 * @param {Object.<string, any>} object
 * @return {Object.<string, true>} The filtered object
 */
export const keepTrueValues = (object) => {
    const objectCleaned = Object.keys(object).reduce((acc, key) => {
        if (object[key] === true) {
            acc[key] = object[key];
        }
        return acc;
    }, {});

    return objectCleaned;
};

/**
 * Checks which fields were modified and returns those values
 * @param {Object.<string, boolean>|boolean} dirtyFields Which values were modified
 * @param {Object.<string, any>} allValues All the form values
 * @return {Object} The modified values
 */
export function checkDirtyValues(dirtyFields, allValues) {
    // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
    // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
    // Here, we have an object
    return Object.fromEntries(Object.keys(dirtyFields).map((key) => [key, checkDirtyValues(dirtyFields[key], allValues[key])]));
}
