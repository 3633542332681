// @ts-check
/**
 * get timestamps from op
 *
 * @param {object} opData
 * @return {{roomLockEnd: string, roomLockStart: string}}
 */
const getTimestamps = (opData) => {
    let roomLockStart = null;
    let roomLockEnd = null;

    // /real, /ki/published, /ki/new have timestamps of duraRoomLockPre and duraRoomLockPost
    if (opData._internalTimestamps?.duraRoomLockPre && opData._internalTimestamps.duraRoomLockPost) {
        roomLockStart = opData._internalTimestamps.duraRoomLockPre.dtStart;
        roomLockEnd = opData._internalTimestamps.duraRoomLockPost.dtEnd;
    }

    // /customer has timestamps of duraRoomLock
    if (opData._internalTimestamps?.duraRoomLock) {
        roomLockStart = opData._internalTimestamps.duraRoomLock.dtStart;
        roomLockEnd = opData._internalTimestamps.duraRoomLock.dtEnd;
    }

    return {roomLockStart, roomLockEnd};
};

export default getTimestamps;
