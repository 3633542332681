// @ts-check
/**
 * Get the participantCategory for the given healthcareService
 * The below condition is for the compatibility between the different versions of fe and settings but will be adjusted in the #15289
 *
 * @param {Object} params
 * @param {ParticipantCategoriesForHealthcareService[]} [params.participantCategoriesForHealthcareService]
 * @param {string} params.hcServiceId
 * @return {ParticipantCategoriesSettings}
 */
export const getParticipantCategories = ({participantCategoriesForHealthcareService, hcServiceId}) => {
    const hcServiceCategory = participantCategoriesForHealthcareService?.find(
        (categoryForHealthcareService) => categoryForHealthcareService.healthcareServiceId === hcServiceId
    );
    const defaultCategory = participantCategoriesForHealthcareService?.find(
        (categoryForHealthcareService) => categoryForHealthcareService.healthcareServiceId === "default"
    );
    return hcServiceCategory?.categories || defaultCategory?.categories || {};
};
