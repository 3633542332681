// @ts-check
import config from "../../config/config.json";
import {format, getDateTimeFromISO} from "./luxon_helpers";

/**
 * format the date of birth in the given format
 *
 * @param {string} birthDate The birthdate
 * @param {string} dateFormat
 * @return {string} The age of the person
 */
export const formatBirthDate = (birthDate, dateFormat) => {
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;
    const birthDateLuxon = getDateTimeFromISO(birthDate);
    return dateFormat && birthDateLuxon.isValid ? format(birthDateLuxon, dateFormat) : HYPHEN;
};
