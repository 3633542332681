// @ts-check

/**
 * check if the given priority is an emergency
 *
 * @param {number} priority
 * @param {number} emergencyThreshold
 * @return {boolean}
 */
export const isEmergencyFunc = (priority, emergencyThreshold) => priority >= emergencyThreshold;
