// @ts-check
/**
 * get practitionerIds of given role from opData
 * @param {NextOrOpTeam} team
 * @param {String} role
 * @return {String|null} practitionerIds
 */
const getPractitionerByRole = (team, role) => {
    let practitionerId = null;
    if (team) {
        Object.keys(team).forEach((category) => {
            Object.keys(team[category]).forEach((pracRole) => {
                if (pracRole === role && team[category][role]) {
                    practitionerId = team[category][role];
                }
            });
        });
    }
    return practitionerId;
};

export default getPractitionerByRole;
