/**
 * get discipline from opData
 * @param {object} opData
 * @return {string} discipline id (not name)
 */
const getDiscipline = (opData) => {
    let disciplines = null;
    if (opData._healthcareService && opData._healthcareService.display) {
        disciplines = opData._healthcareService.reference;
    }
    return disciplines;
};

export default getDiscipline;
