// @ts-check
import {t} from "i18next";

import config from "../../config/config.json";

// The order of the practitioner groups in OP Box
const orderOfCategories = {cardiologists: 1, surgeons: 2, anesthesias: 3, surgeryNurses: 4, anesthesiaNurses: 5, other: 6};

/**
 * format the surgeon presenting
 *
 * @example Name if the surgeon is not surgeon presenting
 * @example Name* if the surgeon is surgeon presenting and attending
 * @example (Name*) if the surgeon is surgeon presenting and NOT attending
 * @param {string} name
 * @param {boolean} isAttending
 * @param {boolean} isSurgeonPresenting
 * @return {string}
 */
export const formatSurgeon = (name, isAttending, isSurgeonPresenting) =>
    !isSurgeonPresenting ? name : isAttending ? `${name}*` : `(${name}*)`;

/**
 * format op team with real names
 * Each group is separated with double slash and each name within a group is separated with vertical slash
 *
 * @example "Name 1 | Name 2 // Name 3 | Name 4 // - // Name 5"
 * @param {OpTeam} team
 * @param {Array<{id: String, name: String}>} teamNameList
 * @param {SurgeonPresenting} surgeonPresenting should be shown in the surgeons group
 * @return {String}
 */
export const formatOpTeam = (team, teamNameList, surgeonPresenting) => {
    const {
        TEXT_PUNCTUATION: {VERTICAL_SLASH, HYPHEN, DOUBLE_SLASH}
    } = config;
    return Object.entries(team)
        .sort(([keyA], [keyB]) => orderOfCategories[keyA] - orderOfCategories[keyB])
        .map(([group, value]) => {
            // get formatted names within a group
            const propertyNames =
                value?.map((id) => {
                    const name = teamNameList.find((nameObject) => nameObject.id === id).name;
                    return formatSurgeon(name, surgeonPresenting?.isAttending, id === surgeonPresenting?.reference);
                }) || [];

            // Add at the last if surgeonPresenting is not in the surgeons
            const isSurgeonPresentingInTheSurgeons = team?.surgeons?.includes(surgeonPresenting?.reference);
            if (group === "surgeons" && surgeonPresenting?.reference && !isSurgeonPresentingInTheSurgeons) {
                const name = teamNameList.find((nameObject) => nameObject.id === surgeonPresenting.reference)?.name || t("App.unknown");
                const {isAttending} = surgeonPresenting;
                propertyNames.push(formatSurgeon(name, isAttending, true));
            }
            // concatenate with vertical slash
            return propertyNames.length ? propertyNames.join(VERTICAL_SLASH) : HYPHEN;
        })
        .join(DOUBLE_SLASH);
};
