// @ts-check
import {diffDateTime, getDateTimeFromISO, nowDateTime} from "./luxon_helpers";

/**
 * calculate the age of a person
 *
 * @param {string} birthDate The birthdate
 * @return {number} The age of the person
 */
const calculateAge = (birthDate) => {
    const birthDateLuxon = getDateTimeFromISO(birthDate);
    const now = nowDateTime();
    const age = Math.floor(diffDateTime(now, birthDateLuxon, "years"));

    return birthDate && birthDateLuxon.isValid ? age : null;
};

export default calculateAge;
