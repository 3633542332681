// @ts-check
/**
 * Filter the rooms base on the given filters
 * @param {Array<String>} roomsFilter The list rooms to filter
 * @param {Array<String>} disciplinesFilter The list of disciplines to filter
 * @param {Array<string>} occupiedOpRooms The occupied rooms
 * @param {Object.<string, string[]>} disciplineRoomsMapping A dictionary mapping disciplines to op-rooms
 * @param {Array<{name: string, id:string}>} roomInfos an array of all rooms {id, name}
 * @return {Array<string>} The ids of the filtered rooms to be displayed
 */
const checkRoomsFilter = (roomsFilter, disciplinesFilter, occupiedOpRooms, disciplineRoomsMapping, roomInfos) => {
    const pureRoomsFilter = roomsFilter.filter((f) => f !== "all" && f !== "plannedOps" && f !== "emptyRooms");

    // Filter by discipline
    let disciplineRooms = [];
    Object.keys(disciplineRoomsMapping).forEach((d) => {
        if (disciplinesFilter.includes(d)) {
            disciplineRooms = disciplineRooms.concat(disciplineRoomsMapping[d]);
        }
    });
    disciplineRooms = [...new Set(disciplineRooms)];
    disciplineRooms.sort();

    // Combine disciplinesFilter and roomsFilter
    let newRooms = disciplinesFilter.length !== 0 ? disciplineRooms : roomInfos.map((room) => room.id);
    if (roomsFilter.includes("plannedOps") && roomsFilter.includes("emptyRooms")) {
        newRooms = [];
    } else if (roomsFilter.includes("plannedOps")) {
        if (disciplinesFilter.length !== 0) {
            newRooms = disciplineRooms.filter((dRoom) => occupiedOpRooms.includes(dRoom));
        } else {
            newRooms = occupiedOpRooms;
        }
    } else if (roomsFilter.includes("emptyRooms")) {
        if (disciplinesFilter.length !== 0) {
            newRooms = disciplineRooms.filter((dRoom) => !occupiedOpRooms.includes(dRoom));
        } else {
            newRooms = roomInfos.filter((room) => !occupiedOpRooms.includes(room.id)).map((room) => room.id);
        }
    }

    // Filter by selected rooms
    if (pureRoomsFilter.length > 0) {
        newRooms = newRooms.filter((r) => pureRoomsFilter.includes(r));
    }
    return newRooms;
};

export default checkRoomsFilter;
