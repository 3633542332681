// @ts-check

import {plusDT} from "./luxon_helpers";

/**
 * Get dates in the period
 *
 * @param {DateTimeType} fromDate
 * @param {DateTimeType} toDate
 * @return {Array<DateTimeType>}
 */
function getDatesInPeriod(fromDate, toDate) {
    const result = [];

    for (let i = fromDate; i <= toDate; i = plusDT(i, "day", 1)) {
        result.push(i);
    }
    return result;
}

export {getDatesInPeriod};
