/**
 * loggin for frontend
 * https://next-data-service.visualstudio.com/Developer/_wiki/wikis/Developer.wiki/953/Logging-NEXT
 */
import pino from "pino";

import axios from "../middleware/axios.middelware";

// Set options for base logger
const options = {
    // name: "nextor-fe-browserXXX", // couldn't pass to transmit.send
    // timestamp: pino.stdTimeFunctions.isoTime,
    browser: {
        write: {
            // override console.log to avoid logs in browser console
            info: (o) => {},
            warn: (o) => {},
            error: (o) => {}
        },
        transmit: {
            level: "info",
            send: (level, logEvent) => {
                if (logEvent.level.value > 20) {
                    axios.post(global.NODE_ENV === "local" ? "/logs" : global.LOG_URL + global.LOG_NAME || "/logs", {
                        "level": logEvent.level.value,
                        "time": new Date(logEvent.ts).toISOString(),
                        "env": global.NODE_ENV,
                        "msg": logEvent.messages[0],
                        "data": logEvent.messages.slice(1),
                        "name": "nextor-fe-browser",
                        "version": global.SERVICE_VERSION,
                        "kubernetes.container_name": "fe",
                        "kubernetes.namespace_name": global.SERVICE_NAMESPACE,
                        "kubernetes.labels.version": global.SERVICE_VERSION
                    });
                }
            }
        }
    }
};

const logger = pino(options);
export default logger;
