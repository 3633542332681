import {SLOT_TYPE} from "../components/legend/legend";

/**
 * filter disciplineSlots per visibleSlots
 * @param {DisciplineSlots[]} disciplineSlots
 * @param {array<string>} visibleDisciplines
 * @return {DisciplineSlots[]}
 */
const filterVisibleSlots = (disciplineSlots, visibleDisciplines) => {
    const emergencyConditions = [SLOT_TYPE.ALL, SLOT_TYPE.EMERGENCY];
    return (
        disciplineSlots?.filter((slot) => {
            // Check if slot is emergency and visible
            const isEmergencyVisible = slot.isEmergency && emergencyConditions.some((cond) => visibleDisciplines.includes(cond));
            // Check if slot is elective and visible
            const electiveConditions = [SLOT_TYPE.ALL, ...slot.healthcareServices];
            const isElectiveVisible = !slot.isEmergency && electiveConditions.some((cond) => visibleDisciplines.includes(cond));
            return isEmergencyVisible || isElectiveVisible;
        }) ?? []
    );
};

export default filterVisibleSlots;
