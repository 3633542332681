/**
 * get intervention from opData
 * @param {object} opData
 * @return {string} opRoom
 */
const getRoom = (opData) => {
    let opRoom = "N/A";
    if (opData._location && opData._location.reference) {
        opRoom = opData._location.reference;
    }
    return opRoom;
};

export default getRoom;
